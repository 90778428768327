import { createSlice } from '@reduxjs/toolkit'

export interface CnASearchState {
    search_params: any,
    show_results: boolean,
}

const initialState: CnASearchState = {
    search_params: [],
    show_results: false,
}

export const connectAndAlignSearchSlice = createSlice({
    name: 'tool_cna_search',
    initialState,
    reducers: {
        setCnASearchParams: (state, action) => {
            state.search_params = action.payload
            state.show_results = true
        },
        setCnASearchHideResults: (state) => {
            state.show_results = false
        },
    },
})

// Action creators are generated for each case reducer function
export const { setCnASearchParams, setCnASearchHideResults } = connectAndAlignSearchSlice.actions
