import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { constants } from '../../../config/constants';
import { zynity_website } from '../../../config/external-routes';
import { loadAdminContent } from '../../../helpers/loadAdminContent';
import { Loading } from '../../ui/loading/Loading';
import { Accordion } from './accordion/Accordion';
import './body.scss';

export const Body = () => {
    const yii_url = zynity_website.yii_website_url;
    const user = useSelector(state => state.auth)
    const user_organizations = useSelector(state => state.user_organizations)

    const [userNewsContent, setUserNewsContent] = useState(false)

    loadAdminContent(constants.admin_contents.user_news, user.access_token).then((response) => {
        setUserNewsContent(response)
    });

    if (user_organizations.isLoading) {
        return (
            <Loading centerPage={true} />
        )
    }

    const organizations = user_organizations.data.data;

    return (
        <div className="row">
            <div className="col-12 col-md-9">
                <div className="row">
                    <div className="col-12 org-org-header">
                        <h5>Organization / Team
                            <a href={`${yii_url}/organization/addOrg`} className="add-org-btn">
                                <div>Add Org</div>
                            </a>
                        </h5>
                    </div>

                </div>

                <div className="row">
                    <div className="col-12 p-0">
                        {
                            organizations.map(org => (
                                <Accordion org={org} user={user} key={org.id} />
                            ))
                        }

                    </div>
                </div>

            </div>

            {
                userNewsContent &&
                    (
                        <div className="col-12 col-md-3">
                            <div className="usernews">
                                <h5>Member News</h5>
                                <div dangerouslySetInnerHTML={{__html: userNewsContent}}></div>
                            </div>
                        </div>
                    )
            }


        </div>
    )
}
