import React from 'react';
import './header.css'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
// @ts-ignore
import { zynity_website } from '../../config/external-routes';
import { useSelector } from 'react-redux'



export const Header = () => {

	const yii_url = zynity_website.yii_website_url;
	const user = useSelector((state:any) => state.auth)
	
	return (
		<nav className="nav navbar-light bg-white d-flex align-items-center justify-content-center">
			<li className="nav-item hoverable">
				<button id="dropdownZynitySlogan" type="button" className="btn dropdown-toggle remove-arrow color-teal zynity-slogan-button" data-bs-toggle="dropdown" aria-expanded="false">
					<img src={require('../../assets/images/zynity_logo.png')}
					width="100"></img>
				</button>
				
				<ul className="dropdown-menu zynity-slogan-item" aria-labelledby="dropdownZynitySlogan">
					<li>Creating harmony among the parts!</li>
				</ul>
			</li>
			<li className="nav-item">
				<Link to="/" className="linkComunity">
					<div className="comunityBtn">
						My&nbsp;Community
						<img src={require('../../assets/images/community_Icon.png')} width="25"></img>
					</div>
				</Link>
			</li>
			{
				user.is_coach &&
				<li className="nav-item ms-2">
					<Link to="/coach">
						<img src={require('../../assets/images/coach_notes_icon.png')} width="40"></img>
					</Link>
				</li>
			}
			<li className="nav-item ms-2">
				<button id="dropdownUserMenu" type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false">
					{user.know_as === undefined ? 'Menu' : `${user.know_as}'s Menu`}
				</button>

				<ul className="dropdown-menu" aria-labelledby="dropdownUserMenu">
					<li><Link to="/" className="dropdown-item">My Community</Link></li>
					<li><Link to="/user/profile" className="dropdown-item">My Profile</Link></li>
					{
						user.is_coach &&
						<li><Link to="/coach" className="dropdown-item">Coach Dashboard</Link></li>
					}
					{
						user.visibility_profile == 'Zadmin' &&
						<li><Link to="/admin/z-admin" className="dropdown-item">Z Admin</Link></li>
					}
					{
						user.has_assessment &&
						<li><Link to="/leadership-assessment" className="dropdown-item">Assessment</Link></li>
					}
					{
						(user.id == 5 || user.id == 255) &&
						<li><a className="dropdown-item" href={zynity_website.wiki} target='_blank'>Member Guide</a></li>
					}
					<li><Link to="/how-lead" className="dropdown-item">How You Lead</Link></li>
					<li><Link to="/notifier-app" className="dropdown-item">Notifier App</Link></li>
					<li><Link to="/logout" className="dropdown-item">Logout</Link></li>
				</ul>
			</li>
			<li className="nav-item ms-2">
				<button id="dropdownBurgerMenu" type="button" className="btn dropdown-toggle remove-arrow" data-bs-toggle="dropdown" aria-expanded="false">
				<FontAwesomeIcon icon={faBars} />
				</button>

				<ul className="dropdown-menu" aria-labelledby="dropdownBurgerMenu">
					<li><a className="dropdown-item" target="_blank" href={zynity_website.public}>Public Site</a></li>
					<li><a className="dropdown-item" href={`${yii_url}/site/helpRequest`}>Help Request</a></li>
					<li><Link to="/logout" className="dropdown-item">Logout</Link></li>
				</ul>
			</li>

		</nav>
	)
}
