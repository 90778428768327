import { createSlice } from '@reduxjs/toolkit'

export interface CoachNotesState {
    isLoading: boolean,
    data: object,
    coachNoteOpen: number,
}

const initialState: CoachNotesState = {
    isLoading: true,
    data: null,
    coachNoteOpen: 0,
}

export const coachNotesSlice = createSlice({
    name: 'coach_notes',
    initialState,
    reducers: {
        startLoading: (state) => {
            state.isLoading = true;
        },
        setCoachNotes: (state, action) => {
            state.data = action.payload
            state.isLoading = false
        },
        setCoachNoteOpen: (state, action) => {
            state.coachNoteOpen = action.payload
        }
    },
})

// Action creators are generated for each case reducer function
export const { startLoading, setCoachNotes, setCoachNoteOpen } = coachNotesSlice.actions
