import React, {useEffect, useState} from 'react'
import Nav from 'react-bootstrap/Nav';
import Accordion from 'react-bootstrap/Accordion';
import './coachNotes.scss';
import {DatumAccordionItem} from "../components/DatumAccordionItem";
import {HeadlineModal} from "../../../ui/tools/HeadlineModal";
import axios from "axios";
import {zynity_api} from "../../../../config/external-routes";
import {useDispatch, useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {getCoachNotes, setCoachNoteOpen} from "../../../../store/coaches/teams/coach_notes";
import {changePriority} from "../../../../helpers/changeListOrder";
import {ReminderModal} from "../../../ui/tools/ReminderModal";
import Swal from "sweetalert2";


export const CoachNotesContainer = ({coach_notes}:any) => {
    const authenticated_user = useSelector((state: any) => state.auth);
    const {team_id} = useParams();
    const dispatch = useDispatch();
    const [showReminderModal, setShowReminderModal] = useState(false);
    const [isNewReminder, setIsNewReminder] = useState(true);
    const [reminder, setReminder] = useState('');
    const [showCreateCoachNoteModal, setShowCreateCoachNoteModal] = useState(false);
    const [headlineModalSettings, setHeadlineModalSettings] = useState({
        title: 'New Coach Note',
        is_comment: false,
        new_record: true,
        labels: {
            title: 'Note',
            description: 'Story'
        }
    });
    const [coachNoteData, setCoachNoteData] = useState({
        coach_note_content_id: '',
        title: '',
        description: '',
        links: [],
        uploads: [],
    });
    const [coachNoteOpenId, setCoachNoteOpenId] = useState(null);
    const [coachNoteCommentOpenId, setCoachNoteCommentOpenId] = useState(null);
    const [datumModalOpenKey] = useState([coach_notes.coachNoteOpen.toString()]);

    const handleCloseCreateCoachNoteModal = () => {
        setCoachNoteOpenId(null)
        setCoachNoteCommentOpenId(null)
        setShowCreateCoachNoteModal(false)
    };

    const handleCloseReminderModal = () => {
        setCoachNoteOpenId(null)
        setShowReminderModal(false)
    };
    axios.defaults.headers.common = {'Authorization': `Bearer ${authenticated_user.access_token}`}


    const coachNoteSubmit = async () =>
    {
        let coach_id = authenticated_user.coach.id;
        let url = zynity_api.add_coach_note.replace('{coach_id}', coach_id).replace('{team_id}', team_id);

        if(headlineModalSettings.is_comment === false) {
            if(coachNoteData.title.trim() == '') {
                Swal.fire({
                    icon: 'warning',
                    text: 'Please add a note name'
                })
            }
            if(coachNoteData.description.trim() == '') {
                Swal.fire({
                    icon: 'warning',
                    text: 'Please add a description'
                })
            }
            if(headlineModalSettings.new_record === true) {
                await axios.post(`${url}`, coachNoteData)
                    .then((response) => {
                        dispatch( getCoachNotes(team_id) );
                        dispatch(setCoachNoteOpen(response.data.id))
                    }).catch((error) => {
                        throw error;
                    })
            } else {
                url = `${url}/${coachNoteOpenId}`
                await axios.put(`${url}`, coachNoteData)
                    .then((response) => {
                        dispatch( getCoachNotes(team_id) );
                        dispatch(setCoachNoteOpen(coachNoteOpenId))
                    }).catch((error) => {
                        throw error;
                    })
            }
        } else {
            if(coachNoteData.description.trim() == '') {
                Swal.fire({
                    icon: 'warning',
                    text: 'Please add a description'
                })
            }
            url = `${url}/${coachNoteOpenId}/comments`
            if(coachNoteCommentOpenId !== null && coachNoteCommentOpenId > 0) {
                url = `${url}/${coachNoteCommentOpenId}`
            }

            if(headlineModalSettings.new_record) {
                await axios.post(`${url}`, coachNoteData)
                    .then((response) => {
                        dispatch( getCoachNotes(team_id) );
                        dispatch(setCoachNoteOpen(coachNoteOpenId))
                    }).catch((error) => {
                        throw error;
                    })
            } else {
                await axios.put(`${url}`, coachNoteData)
                    .then((response) => {
                        dispatch( getCoachNotes(team_id) );
                        dispatch(setCoachNoteOpen(coachNoteOpenId))
                    }).catch((error) => {
                        throw error;
                    })
            }
        }
    }

    const clickNewCoachNote = () => {
        setHeadlineModalSettings({
            ...headlineModalSettings,
            title: 'New Coach Note',
            new_record: true,
            is_comment: false,
        });
        setCoachNoteData({
            coach_note_content_id: '',
            title: '',
            description: '',
            links: [],
            uploads: [],
        });
        setShowCreateCoachNoteModal(true);
    }

    const clickEditCoachNote = (coach_note_id:any) => {
        setCoachNoteOpenId(coach_note_id)
        setHeadlineModalSettings({
            ...headlineModalSettings,
            title: 'Edit Coach Note',
            new_record: false,
            is_comment: false,
        });

        let coach_note = coach_notes.data.find((coach_note: { id: any; }) => coach_note.id === coach_note_id);

        let links = coach_note.links.map((link: { id: any; links: any; }) => {
            return {
                id: link.id,
                link: link.links,
            };
        });

        let uploads = coach_note.uploads.map((upload: { id: number, fileName: string, displayName: string; }) => {
            return {
                id: upload.id,
                file_name: upload.fileName,
                display_name: upload.displayName,
            };
        });

        setCoachNoteData({
            coach_note_content_id: coach_note.datum_content_id,
            title: coach_note.title,
            description: coach_note.description,
            links: links,
            uploads: uploads,
        })

        setShowCreateCoachNoteModal(true);
    }

    const clickEditComment = (coach_note_id:any, comment_id:number, reply_id:number = null, reply2_id:number = null) => {
        setCoachNoteCommentOpenId(comment_id)
        setCoachNoteOpenId(coach_note_id)
        let coach_note = coach_notes.data.find((coach_note: { id: any; }) => coach_note.id === coach_note_id);
        let comment = coach_note.comments.find((comment: { id: any; }) => comment.id === comment_id);

        if(reply_id !== null) {
            comment = comment.comments.find((comment: { id: any; }) => comment.id === reply_id);
            setCoachNoteCommentOpenId(reply_id)
        }

        if(reply2_id !== null) {
            comment = comment.comments.find((comment: { id: any; }) => comment.id === reply2_id);
            setCoachNoteCommentOpenId(reply2_id)
        }


        setHeadlineModalSettings({
            ...headlineModalSettings,
            title: 'Edit Comment',
            new_record: false,
            is_comment: true
        });

        let links = comment.links.map((link: { id: any; links: any; }) => {
            return {
                id: link.id,
                link: link.links,
            };
        });

        let uploads = comment.uploads.map((upload: { id: number, fileName: string, displayName: string; }) => {
            return {
                id: upload.id,
                file_name: upload.fileName,
                display_name: upload.displayName,
            };
        });

        setCoachNoteData({
            coach_note_content_id: coach_note.datum_content_id,
            title: '',
            description: comment.comment,
            links: links,
            uploads: uploads,
        })

        setShowCreateCoachNoteModal(true);
    }

    const clickAddComment = (coach_note_id:any, parent_comment:any = null) => {
        let coach_note = coach_notes.data.find((coach_note: { id: any; }) => coach_note.id === coach_note_id);
        setCoachNoteOpenId(coach_note_id)
        setCoachNoteCommentOpenId(parent_comment)
        setHeadlineModalSettings({
            ...headlineModalSettings,
            title: 'New Comment',
            new_record: true,
            is_comment: true
        });

        setCoachNoteData({
            coach_note_content_id: coach_note.datum_content_id,
            title: '',
            description: '',
            links: [],
            uploads: [],
        });
        setShowCreateCoachNoteModal(true);
    }

    const deleteCoachNote = async (coach_note_id:any) => {
        let coach_note = coach_notes.data.find((coach_note: { id: any; }) => coach_note.id === coach_note_id);

        let coach_id = authenticated_user.coach.id;
        let url = zynity_api.add_coach_note.replace('{coach_id}', coach_id).replace('{team_id}', team_id);

        url = `${url}/${coach_note.id}?coach_note_content_id=${coach_note.datum_content_id}`

        await axios.delete(`${url}`)
            .then((response) => {
                dispatch( getCoachNotes(team_id) );
                dispatch(setCoachNoteOpen(coach_note.id))
            }).catch((error) => {
                throw error;
            })
    }



    const handleChangeListOrder = async (item_id: number, newPriority: number) => {
        let coach_notes_list = changePriority(coach_notes.data, item_id, newPriority).map((note:any) => {
            return note.datum_content_id
        });

        let coach_id = authenticated_user.coach.id;
        let url = zynity_api.add_coach_note.replace('{coach_id}', coach_id).replace('{team_id}', team_id);
        await axios.put(`${url}/change_list_order`, {datum_content_id: coach_notes_list})
            .then((response) => {
                console.log("Coach note update", coach_notes_list);
                dispatch( getCoachNotes(team_id) );
                dispatch(setCoachNoteOpen(item_id))
            }).catch((error) => {
                throw error;
            })
    }

    const handleOpenReminder = (coach_note_id:number) => {

        let coach_note = coach_notes.data.find((coach_note: { id: any; }) => coach_note.id === coach_note_id);
        if(coach_note.datumNotificationReminderId != null && coach_note.datumNotificationReminderId > 0 &&
            coach_note.datumNotificationReminderComments.trim() != '')
        {
            setIsNewReminder(false)
            setReminder(coach_note.datumNotificationReminderComments)
        } else {
            setIsNewReminder(true)
            setReminder('')
        }
        setShowReminderModal(true)
        setCoachNoteOpenId(coach_note_id)
    }

    const handleSubmitReminder = async (type: any) => {
        let coach_note = coach_notes.data.find((coach_note: { id: any; }) => coach_note.id === coachNoteOpenId);
        let coach_id = authenticated_user.coach.id;
        let url = zynity_api.add_coach_note.replace('{coach_id}', coach_id).replace('{team_id}', team_id);
        url = `${url}/${coachNoteOpenId}/reminders`

        if(type == 'delete' || !isNewReminder) {
            url = `${url}/${coach_note.datumNotificationReminderId}`
            let data = {
                coach_note_content_id: coach_note.datum_content_id,
                comments: reminder,
                status: "unread"
            }

            if(type == 'delete') {
                data.status = "read"
            } else {
                if(reminder.trim() == '') {
                    Swal.fire({
                        icon: 'warning',
                        text: 'Please add your reminder'
                    })
                }
            }

            await axios.put(`${url}`, data)
                .then((response) => {
                    dispatch( getCoachNotes(team_id) );
                    dispatch(setCoachNoteOpen(coachNoteOpenId))
                }).catch((error) => {
                    throw error;
                })
            //Update/Delete
        } else {
            if(reminder.trim() == '') {
                Swal.fire({
                    icon: 'warning',
                    text: 'Please add your reminder'
                })
            }

            //Insert
            let data = {
                coach_note_content_id: coach_note.datum_content_id,
                comments: reminder
            }
            await axios.post(`${url}`, data)
                .then((response) => {
                    dispatch( getCoachNotes(team_id) );
                    dispatch(setCoachNoteOpen(coachNoteOpenId))
                }).catch((error) => {
                    throw error;
                })
        }
    }

    return (
        <div className="container coach-notes-container">
            <div className="col-12">
                <Nav variant="tabs" defaultActiveKey="/home">
                    <Nav.Item>
                        <Nav.Link style={{backgroundColor: '#FDF68C', color: 'black'}}>
                            Coach Notes <button
                            type="button"
                            className="btn btn-teal btn-sm"
                            onClick={clickNewCoachNote}
                        >✚</button></Nav.Link>
                        <HeadlineModal
                            showCreateModal={showCreateCoachNoteModal}
                            handleCloseModal={handleCloseCreateCoachNoteModal}
                            data={coachNoteData}
                            setData={setCoachNoteData}
                            submitHandled={coachNoteSubmit}
                            settings={headlineModalSettings}
                        />
                        <ReminderModal
                            showCreateModal={showReminderModal}
                            handleCloseModal={handleCloseReminderModal}
                            isNewReminder={isNewReminder}
                            reminder={reminder}
                            setReminder={setReminder}
                            submitHandled={handleSubmitReminder}
                        />
                    </Nav.Item>
                </Nav>
            </div>

            <div className="col-12 mt-2">
                <Accordion defaultActiveKey={datumModalOpenKey} alwaysOpen>
                    {
                        coach_notes.data.map((coach_note:any) => (
                            <DatumAccordionItem eventKey={coach_note.id.toString()}
                                                key={coach_note.id}
                                                datum={coach_note}
                                                total_coach_notes={coach_notes.data.length}
                                                handleEditItem={clickEditCoachNote}
                                                handleAddComment={clickAddComment}
                                                handleEditComment={clickEditComment}
                                                handleDeleteItem={deleteCoachNote}
                                                handleChangeListOrder={handleChangeListOrder}
                                                handleOpenReminder={handleOpenReminder}
                                                isSubItem={false}
                                                readOnly={false}
                                                clickNewItem={clickNewCoachNote}
                            />
                        ))
                    }
                </Accordion>
            </div>
        </div>
    )
}
