import React from 'react'
import { zynity_website } from '../config/external-routes'

export const LogOut = () => {
    window.location.href = zynity_website.yii_website_logout_url
    return (
        <>
        </>
    )
}
