import React from 'react'
import {Link} from "react-router-dom";
import './accordion.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { zynity_website } from '../../../../config/external-routes';
import { Team } from './team/Team';

export const Accordion = ({ org, user }) => {
    const yii_url = zynity_website.yii_website_url;
    const { id, name, total_notifications, is_admin: is_admin_from_this_org, is_coach: is_coach_from_this_org, teams } = org;

    const submitForm = (formId) => {
        document.getElementById(formId).submit();
    }

    return (
        <div className="accordion accordion-flush user_home_body_accordion mt-1">
            <div className="accordion-item">
                <h2 className="accordion-header" id={`accordionOrgId${id}`}>
                    <div className="row pt-1">
                        <div className="col-7 col-md-6">
                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#flush-collapse-org${id}`} aria-expanded="false" aria-controls="flush-collapseOne">
                                {name}
                            </button>
                        </div>

                        <div className="col-5 col-md-6 d-flex align-items-center">
                            <div className="hoverable d-flex align-items-center">
                                <button id="dropdownBurgerMenu" type="button" className="btn dropdown-toggle remove-arrow" data-bs-toggle="dropdown" aria-expanded="false">
                                    <FontAwesomeIcon icon={faBars} />
                                </button>

                                <ul className="dropdown-menu" aria-labelledby="dropdownBurgerMenu">
                                    {
                                        is_admin_from_this_org &&
                                        <>
                                            <li><Link to={`/organizations/${id}`} className="dropdown-item" >Admin Dashboards</Link></li>
                                        </>
                                    }
                                    <form id={`OrgProfileForm${id}`} action={`${yii_url}/userHome/index`} method="post" className={`d-none`}>
                                        <input type="hidden" name="orgId" value={id}></input>
                                        <input type="hidden" name="menuAction" value='OrgDetails'></input>
                                    </form>
                                    <li><a className="dropdown-item" onClick={() => submitForm(`OrgProfileForm${id}`)}>Org Profile</a></li>

                                    <form id={`AddTeamForm${id}`} action={`${yii_url}/userHome/index`} method="post" className={`d-none`}>
                                        <input type="hidden" name="orgId" value={id}></input>
                                        <input type="hidden" name="menuAction" value='AddTeam'></input>
                                    </form>
                                    <li><a className="dropdown-item" onClick={() => submitForm(`AddTeamForm${id}`)}>Add Team</a></li>
                                    {
                                        (is_admin_from_this_org || is_coach_from_this_org) &&
                                        <>
                                            <form id={`RoiForm${id}`} action={`${yii_url}/userHome/index`} method="post" className={`d-none`}>
                                                <input type="hidden" name="orgId" value={id}></input>
                                                <input type="hidden" name="coachId" value={user.is_coach.id}></input>
                                                <input type="hidden" name="coachMemberId" value={user.id}></input>
                                                <input type="hidden" name="menuAction" value='ROI'></input>
                                            </form>
                                            <li><a className="dropdown-item" onClick={() => submitForm(`RoiForm${id}`)} title="Org ROI is prepared by assigned coach">ROI</a></li>
                                            <li><a href={`${yii_url}/resources/ET_Coach_Plan.pdf`} className="dropdown-item" title="Org ROI is prepared by assigned coach" target="_blank">Coach Plan</a></li>
                                        </>
                                    }
                                </ul>
                            </div>
                            {
                                total_notifications > 0 &&
                                <span className="badge rounded-pill bg-danger badge-total-notifications" data-bs-toggle="tooltip" data-bs-placement="top" title="New Content">{total_notifications}</span>
                            }
                        </div>
                    </div>
                </h2>
                <div className="pb-1">
                    <div id={`flush-collapse-org${id}`} className="accordion-collapse collapse" aria-labelledby={`accordionOrgId${id}`} data-bs-parent="#accordionFlushExample">
                        {
                            teams?.map(team => (
                                <Team team={team} org_id={org.id} key={team.id} />
                            ))
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
