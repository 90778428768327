import React from 'react'
import {Button, Modal} from "react-bootstrap";
import Select from "react-select";
import ModalDialog from "react-bootstrap/ModalDialog";
import Draggable from 'react-draggable';

class DraggableModalDialog extends React.Component {
    render() {
        return <Draggable handle=".draggableHandler"><ModalDialog {...this.props} />
        </Draggable>
    }
}

export const GuideIframeModal = ({showGuideModal, handleCloseGuideModal}:any) => {
    return (
        <>
            <Modal show={showGuideModal} onHide={handleCloseGuideModal}
                   dialogAs={DraggableModalDialog}
            >
                <Modal.Header closeButton className='bg-primary draggableHandler' style={{cursor: 'grab', color: 'white'}}>
                    <Modal.Title >Guide - Team Collab App</Modal.Title>
                </Modal.Header>
                <Modal.Body className='row d-flex align-items-center'>
                    <iframe
                        src="https://docs.google.com/document/d/e/2PACX-1vTs9NeqxoNOq7wD-FgU14XDM9C9GKEi5Xy_yDL5hFZHDKaSCVwUzvQDxt9-I87MlgaDX6o0DpDiaAHV/pub?embedded=true"
                        style={{ width: '100%', height: '500px', border: 'none' }}
                        title="Guide Document"
                    ></iframe>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleCloseGuideModal}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}
